
import {defineComponent} from "vue";

import {Api} from "@/store/enums/StoreEnums";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import AddNoteToEmployeeModal from "@/views/employee/modals/AddNote.vue"
import EmployeeFireModal from "@/views/employee/modals/FireEmployeModal.vue";
import {dateformat} from "@/core/filters/filters";
import Swal from "sweetalert2";
import {IEmployee} from "@/model/employee";
import router from "@/router";

export default defineComponent({
  name: "EmployeeDetail",
  components: {
    EmployeeFireModal,
    AddNoteToEmployeeModal
  },
  setup() {
    return {
      dateformat
    }
  },
  data() {
    return {
      employeeId: this.$route.params.id_emp,
      loaded: false,
      currentRoleLoaded: false,
      employee: <IEmployee>{},
      empTimer: 0,
      roleTimer: 0,
      currentRole: {}
    }
  },
  mounted: function () {
    this.getCurrentEmployee()
    MenuComponent.reinitialization();
    setCurrentPageBreadcrumbs("Detail zaměstnance", ["Zaměstnanci"]);

  },
  methods: {
    getCurrentEmployee() {
      ApiService.get(Api.GET_EMPLOYEE, {employee_id: this.employeeId}).then(response => {
        this.employee = response.data
        this.loaded = true;
        ApiService.get(Api.GET_CURRENT_EMPLOYEE_ROLE, {employee_id: this.employeeId})
          .then(response => {
            this.currentRole = response.data;
            this.currentRoleLoaded = true;
          })
      })

    },
    deleteEmployee() {
      let emp_id = this.employeeId;
      Swal.fire({
        title: "Opravdu se přejete natrvalo odstranit zaměstnance?",
        html: "<b>" + this.employee.name + " " + this.employee.surname + "</b><br/> Odstraní se veškerá historie a zaměstnace nebude možné dohledat.",
        icon: "warning",
        buttonsStyling: false,
        showCloseButton: true,
        confirmButtonText: "Ano, odstranit zaměstnance",
        cancelButtonText: "Ne!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
          cancelButton: "btn fw-bold btn-btn-light-danger",
        },
      }).then(function () {
        console.log("mažeme:" + emp_id);
        ApiService.delete(Api.DELETE_EMPLOYEE, {'employee_id': emp_id}).then(function () {
          router.push({name: 'EmployeeOverview'})
        })
      });

    },
  }
})
;
