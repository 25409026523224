
import {defineComponent, ref} from "vue";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Api} from "@/store/enums/StoreEnums";
import apiService from "@/core/services/ApiService";
import {castData} from "@/core/data/data_prepare";

export default defineComponent({
  name: "EmployeeFireModal",
  data() {
    return {
      formData: {
        terminated_date: "",
        employee_id: this.$route.params.id_emp,
      },
      loading: false,
    }
  },
  methods: {
    submit() {
      let dataFormat = {
        terminated_date: 'date',
      };
      let formRef;
      let EmpFireModalRef;

      if (this.$refs.formRef1 && this.$refs.EmpFireModalRef) {
        formRef = ref(this.$refs.formRef1);
        EmpFireModalRef = ref(this.$refs.EmpFireModalRef);
      } else return;
      if (!formRef.value || !EmpFireModalRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          let data = castData(this.formData, dataFormat);
          this.loading = true;
          apiService.postFrom(Api.FIRE_EMPLOYEE, data, {'employee_id': this.formData.employee_id}).then(() => {
            this.loading = false;
            hideModal(EmpFireModalRef.value);

          }).catch(({response}) => {
            console.log(response);
            Swal.fire({
              text: response.data.ErrorMessage,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Něco se pokazilo!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
        }
      });
    },
  },
});
